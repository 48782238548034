<template>
  <div class="app-container">
    <eHeader
            :query="query"
            @clear="clear"
            :tableStatus="tableStatus"
            @query="queryHandle"
            @export="exportHandle"
            ref="header"
            :show-export="showExport"
    />
    <!--表格渲染-->
    <el-table
            v-loading="loading"
            :data="data"
            size="small"
            stripe
            style="width: 100%;"
            @selection-change="handleSelectionChange"
    >
        <el-table-column
                v-if="selectCheck()"
                type="selection"
                key="1"
        >
        </el-table-column>
      <el-table-column prop="transactionId" label="订单号"/>
      <el-table-column prop="MT4" label="MT账号">
        <template slot-scope="scope">
            <span v-if="scope.row.login!=null">{{scope.row.login}}<br></span>
            <span v-if="scope.row.group!=null">{{scope.row.group}}</span>
            <span v-if="scope.row.login==null&&scope.row.group==null">无</span>
        </template>
      </el-table-column>
      <el-table-column  label="客户信息" width="150">
        <template slot-scope="scope">
            <span v-if="scope.row.userName!=null">{{scope.row.userName}}<br></span>
          <span v-if="scope.row.custId!=null">{{scope.row.custId}}<br></span>
          <span v-if="scope.row.email!=null">{{scope.row.email}}</span>
          <span v-if="scope.row.custId==null&&scope.row.phone==null&&scope.row.email==null">无</span>
        </template>
      </el-table-column>

      <el-table-column prop="manager" label="客户经理">
        <template slot-scope="scope">
            <span v-if="scope.row.managerName!=null">{{scope.row.managerName}}<br></span>
            <span v-if="scope.row.managerId!=null">{{scope.row.managerId}}</span>
            <span v-if="scope.row.managerName==null&&scope.row.managerId==null">无</span>
        </template>
      </el-table-column>
      <el-table-column prop="payType" label="出金类型">
        <template slot-scope="scope">
          <span>{{ getOutType(scope.row.payTypeId) }}</span>
        </template>
      </el-table-column>
      <el-table-column
          v-if="auditPermission"
          prop="payType"
          label="支付渠道"
      >
         <template slot-scope="scope">
          <span>{{ scope.row.payDetail }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="currency" label="支付币种"/>
      <el-table-column prop="amount" label="金额 ($)"/>
      <el-table-column
          v-if="auditPermission"
          prop="status" label="付款状态">
        <template slot-scope="scope">
          <span>{{ parsePayStatus(scope.row.payStatus) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="审核状态">
        <template slot-scope="scope">
          <span>{{ parseWithdrawStatus(scope.row.status) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="提交时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="审核通过时间">
        <template slot-scope="scope">
          <span>{{ getAuditTime(scope.row.statusTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250px" align="center">
        <template slot-scope="scope">
          <el-button
              v-if="checkBtnShow"
              size="mini"
              type="success"
              @click="to(scope.row,false)"
          >查看</el-button>
          <el-button
              v-if="auditBtnShow"
              size="mini"
              type="danger"
              @click="to(scope.row,true)"
          >审核</el-button>

          <el-button
              type="primary"
              size="mini"
              class="my-button"
              v-if="checkPermission(['ADMIN','WITHDRAW_ALL','WITHDRAW_STATUS_UPDATE'])&&tableStatus=='-1'"
              @click="doStatus(scope.row)"
          >
            状态调整
          </el-button>
          <el-button
              type="danger"
              size="mini"
              class="my-button"
              v-if="getDayStatusAdjustBtnShow(scope.row)"
              @click="adjustPayStatusHandle(scope.row)"
          >
            支付状态调整
          </el-button>
          <el-button
            v-if="getWithdrawBtnShow(scope.row)"
            @click="withdrawHandle(scope.row)"
            type="primary"
            size="mini"
            class="my-button"
          >出金</el-button>
        </template>

      </el-table-column>
    </el-table>
      <public-pagination
              :total-page="total"
              :current-page="page"
              @pageChange="pageChange"
              @sizeChange="sizeChange"
      ></public-pagination>

    <el-dialog
            :append-to-body="true"
            :visible.sync="statusLoading"
            title="状态调整"
            width="500px"
    >
     <el-form ref="form" :model="form" size="small" label-width="120px">
      <el-form-item label="申请编号">
        <el-input v-model="form.transactionId" style="width: 250px;" :disabled="true"/>
      </el-form-item>
      <el-form-item label="修改前状态">
        <el-input v-model="form.befStatusDesc" style="width: 250px;" :disabled="true"/>
      </el-form-item>
      <el-form-item label="修改后状态">
         <el-select v-model="form.updateStatus" placeholder="请选择状态">
          <el-option
            v-for="item in withdrawStatusOptions"
            :key="item.key"
            :label="item.display_name"
            :value="item.key">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.remark" type="textarea" :rows="2" style="width: 250px;"/>
      </el-form-item>
    </el-form>
    <div lot="footer" class="dialog-footer" style="text-align: right;">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">调整</el-button>
    </div>
  </el-dialog>
      <audit-submit-bar
        :audit-select-data="multipleSelection"
        @submit="submit"
      ></audit-submit-bar>
      <batch-review-dialog
        :dialog-show="batchDialogShow"
        @close="closeHandle"
        @confirm="cofrimHandle"
      ></batch-review-dialog>
    <detail
        ref="detail"
        :sup_this="sup_this"
        :is-audit="isAudit"
        :data="currentRow"
        :tableStatus="tableStatus"
        :is-withdraw-operate="isWithdrawOperate"
    />
    <pay-status-adjust-dialog
        :dialog-show="payStatusAdjustDialogShow"
        :row-data="currentRow"
        @close="closePayStatusAdjustDialog"
        @edit-success="payStatusAdjustSuccess"
    ></pay-status-adjust-dialog>
  </div>
</template>

<script>
  import { initData } from '@/api/data'
  import { export_json_to_excel,formatJson } from '@/vendor/Export2Excel'
  import { batchAuditRquest } from '@/api/ib/ibMerchantCommission'
  import batchAudit from '@/mixins/batchAudit'
  import batchReviewDialog from '@/components/public/batchReviewDialog'
  import auditSubmitBar from '@/components/public/auditSubmitBar'
import { mapGetters } from 'vuex'
import checkPermission from '@/utils/permission'
import initDataInit from '@/mixins/initData'
import { modifyStatus } from '@/api/cus/tCustDepositWithdraw'
import { parseTime } from '@/utils/index'
import editcommi from './edit';
  import payStatusAdjustDialog from '@/components/finance/withdraw/payStatusAdjustDialog'
  import detail from '@/components/finance/withdraw/detail'
import eHeader from './header'
import { parseWithdrawStatus,parseCurrencyType,parseOutType,parsePayStatus } from '@/views/pt/ib/utils/index'
export default {
  components: {
    eHeader,
    editcommi,
    auditSubmitBar,
    batchReviewDialog,
    detail,
    payStatusAdjustDialog,
  },
  mixins: [initDataInit,batchAudit],
  props:{
     tableStatus: {
      type: Number,
      required: true
    },
    refresh: {
      type: Boolean,
      default: false
    },
    showExport:{
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(['withdrawStatusOptions']),
    // 有查看tab其中的一个tab权限就有查看的权限
    checkBtnShow() {
      return checkPermission(['ADMIN','WITHDRAW_INFO_ALL','WITHDRAW_APPLY_INFO','WITHDRAW_CUST_INFO','WITHDRAW_AUDIT_INFO']);
    },
    auditPermission(){
      return checkPermission(['ADMIN','WITHDRAW_ALL','WITHDRAW_CHECK']);
    },
    auditBtnShow(){
      return this.auditPermission && this.tableStatus != '-1' && this.tableStatus !='6' && this.tableStatus !='99';
    },
  },
  data() {
    return {
      loading: false,
      statusLoading: false,
      sup_this: this,
      selectedRow:null,
      form: {
        id: '',
        transactionId: '',
        chkStatus: 1,
        updateStatus:1,
        befStatusDesc:'',
        remark:''
      },
      currentRow: {},
      isAudit: false,
      isWithdrawOperate: false,
      payStatusAdjustDialogShow: false,
    }
  },
  created() {
    this.$nextTick(() => {
      this.init();
    })
  },
  watch:{
    refresh(newData,oldData){
      this.beforeInit()
      this.init()
    }
  },
  methods: {
    parseTime,
    checkPermission,
    parseWithdrawStatus,
    parseCurrencyType,
    parseOutType,
    parsePayStatus,
    beforeInit() {
      this.url = '/crm/tCustDepositWithdraw'
      const sort = 'id,desc'
      this.params = { page: this.page, size: this.size, sort: sort, type:2 }
      const query = this.query;
      const transactionId = query.transactionId;
      const login = query.login;
      const status = query.status;
      const payType = query.payType;
      const createTime = this.parseTime(query.createTime);
      const updateTime = this.parseTime(query.updateTime);
      const chkStatus = query.chkStatus;
      const managerInfo = query.managerInfo;
      if (this.tableStatus!='-1') {
        this.params['status'] = this.tableStatus;
      }else if (chkStatus!=null&&chkStatus!='') {
        this.params['status'] = chkStatus;
      }
      if (managerInfo!=null && managerInfo!='') { this.params['managerInfo'] = managerInfo }
      if (this.query.custInfo!=null && this.query.custInfo!='') { this.params['custInfo'] = this.query.custInfo }
      if (transactionId!=null && transactionId!='') { this.params['transactionId'] = transactionId }
      if (login!=null && login!='') { this.params['login'] = login }
      if (status!=null && status!='') { this.params['status'] = status }
      if (payType!=null && payType!='') { this.params['payTypes'] = payType.join(); }
      if (createTime!=null && createTime!='') { this.params['createTime'] = createTime }
      if (updateTime!=null && updateTime!='') { this.params['updateTime'] = updateTime }
      if(this.query.withdrawType){
        this.params.payTypeId = this.query.withdrawType;
      }
      if(this.query.startAuditTime){
        this.params.startAuditTime = this.parseTime(this.query.startAuditTime);
      }
      if(this.query.endAuditTime){
        this.params.endAuditTime = this.parseTime(this.query.endAuditTime);
      }
      return true
    },
    queryHandle(){
      this.page = 0;
      this.init();
    },
    to(rowData,isAudit,callback) {
      callback && callback();
      this.isAudit = isAudit;
      this.currentRow = rowData;
      const _this = this.$refs.detail;
      var url = '/crm/tDepositWithdrawEvent';
      const params = { depositWithdrawId: rowData.id};

      _this.getPayFeeConfig();

      initData(url,params).then(res=>{
        _this.events = res.content;
        _this.dialog = true;
      }).catch(err => {
        console.log(err.response.data.message);
      })
    },
    getWithdrawBtnShow(row){
      return checkPermission(['ADMIN','WITHDRAW_ALL','WITHDRAW_CHECK']) && (row.payStatus == 4 && this.tableStatus == 6);
    },
    doStatus(selectedRow){
      this.statusLoading=true
      this.selectedRow=selectedRow
      this.form={
        id: selectedRow.id,
        transactionId: selectedRow.transactionId,
        chkStatus: selectedRow.status,
        updateStatus:1,
        remark:''
      }
      for (var i = this.withdrawStatusOptions.length - 1; i >= 0; i--) {
        if(this.withdrawStatusOptions[i].key==selectedRow.status){
          this.form.befStatusDesc=this.withdrawStatusOptions[i].display_name
        }
      }
    },
    cancel(){
      this.statusLoading=false
      this.form = {
        id: '',
        transactionId:'',
        chkStatus: 1,
        updateStatus:1,
        befStatusDesc:'',
        remark:''
      }
      this.selectedRow=null
    },
    doSubmit(){
      this.loading=true
      const params = { id: this.selectedRow.id, status: this.form.updateStatus}
      if(this.form.remark!=null&&this.form.remark!=''){
        params['auditContent']=this.form.remark
      }
      //接口调用
      modifyStatus(params).then(res => {
        this.$notify({
          title: '调整成功',
          type: 'success',
          duration: 2500
        })
        this.cancel()
        this.loading = false
        this.refresh=!this.refresh
      }).catch(err => {
        this.cancel()
        this.loading = false
      })
    },
    getOutType(payTypeId){
      switch (payTypeId) {
        case 4:
          return '出金至银行卡';
        case 5:
          return '出金至USDT';
        case 6:
          return '出金至OTC365';
      }
    },
    getAuditTime(auditTime){
      if(auditTime){
        return parseTime(auditTime);
      }else {
        return '-'
      }
    },
    clear(){
      this.query = {};
      this.toQuery();
    },
    cofrimHandle(paramsObj){
      let params = {
        ids: this.getIds(),
        auditBy: this.getAuditBy(),
        ...paramsObj
      };
      batchAuditRquest(params).then((res) => {
        this.$notify({
          title: '成功',
          type: "success",
          duration: 2500
        });
        this.batchDialogShow = false;
        this.init();
      })
    },
    exportHandle(){
      this.url = '/crm/tCustDepositWithdraw';
      const sort = 'id,desc';
      let params = {
        sort: sort,
        type:2,
        export: 1,
      }
      const query = this.query;
      const transactionId = query.transactionId;
      const login = query.login;
      const status = query.status;
      const payType = query.payType;
      const createTime = this.parseTime(query.createTime);
      const updateTime = this.parseTime(query.updateTime);
      const chkStatus = query.chkStatus;
      const managerInfo = query.managerInfo;
      if (this.tableStatus!='-1') {
        params['status'] = this.tableStatus;
      }else if (chkStatus!=null&&chkStatus!='') {
        params['status'] = chkStatus;
      }
      if (managerInfo!=null && managerInfo!='') { params['managerInfo'] = managerInfo }
      if (this.query.custInfo!=null && this.query.custInfo!='') { params['custInfo'] = this.query.custInfo }
      if (transactionId!=null && transactionId!='') { params['transactionId'] = transactionId }
      if (login!=null && login!='') { params['login'] = login }
      if (status!=null && status!='') { params['status'] = status }
      if (payType!=null && payType!='') { params['payTypes'] = payType.join(); }
      if (createTime!=null && createTime!='') { params['createTime'] = createTime }
      if (updateTime!=null && updateTime!='') { params['updateTime'] = updateTime }
      if(this.query.withdrawType){
        params.payTypeId = this.query.withdrawType;
      }
      if(this.query.startAuditTime){
        params.startAuditTime = this.parseTime(this.query.startAuditTime);
      }
      if(this.query.endAuditTime){
        params.endAuditTime = this.parseTime(this.query.endAuditTime);
      }
      this.$refs['header'].loading = true;
      initData(this.url, params).then(res => {

        for (let i = 0; i < res.content.length; i++) {
            res.content[i].outType = this.getOutType(res.content[i].payTypeId);
            res.content[i].rmb = (res.content[i].amount * res.content[i].nominalExRate).toFixed(2);
          if(res.content[i].snapshotList){
            for (let j = 0; j < res.content[i].snapshotList.length; j++) {
              if(res.content[i].snapshotList[j].payTypeId == 5){
                res.content[i].usdtAddress = res.content[i].snapshotList[j].custPayDetail.address;
              }
              if(res.content[i].snapshotList[j].payTypeId == 4){
                res.content[i].backName = res.content[i].snapshotList[j].custPayDetail.bankName;
                res.content[i].bankAccount = res.content[i].snapshotList[j].custPayDetail.bankAccount;
              }
              if(res.content[i].snapshotList[j].payTypeId == 6){
                res.content[i].otcAddress = res.content[i].snapshotList[j].custPayDetail.address;
              }
            }
          }
        }
        const tHeader =['客户名称','MT4','出金类型','金额($)','汇率','人民币金额(￥)','银行名称', '银行账号','USDT地址','OTC365地址'];
        const filterVal =['userName','login','outType','amount','nominalExRate','rmb','backName','bankAccount','usdtAddress','otcAddress'];
        const exportData = formatJson(filterVal,res.content);
        let fileName='出金报表';
        export_json_to_excel(tHeader,exportData,fileName);
        this.$refs['header'].loading = false;
      }).catch(err => {
        this.$refs['header'].loading =false
      })
    },
    withdrawHandle(row){
      this.to(row,true,() => {
        this.isWithdrawOperate = true
      });
    },
    getDayStatusAdjustBtnShow(row){
      return checkPermission(['ADMIN','MODIFY_PAY_ALL','MODIFY_PAY_EDIT']) && this.tableStatus == '-1'
    },
    adjustPayStatusHandle(row){
      this.currentRow = row;
      this.payStatusAdjustDialogShow = true;
    },
    closePayStatusAdjustDialog(){
      this.currentRow = {};
      this.payStatusAdjustDialogShow = false;

    },
    payStatusAdjustSuccess(){
      this.currentRow = {};
      this.payStatusAdjustDialogShow = false;
      this.init();
    }
  },
}
</script>

<style scoped>

</style>
